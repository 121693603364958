<template>
  <div class="content">
    <div class="card">
      <img class="logo" src="../../assets/images/logo.png"/>
      <p class="title">企業担当者様向け（ログイン画面）</p>
      <div class="input mail">
        <img src="../../assets/images/login/mail.png" />
        <input v-model="format.userName" placeholder="メールアドレス"/>
      </div>
      <div class="input key">
        <img src="../../assets/images/login/key.png" />
        <input v-model="format.password" type="password" placeholder="パスワード"/>
      </div>

      <div @click="nav(1)" class="button">ログイン</div>

      <div class="desc">
<!--        <img src="../../assets/images/login/que.png"/>-->
        <span @click="nav(2)">パスワードを忘れた方はこちら</span>
      </div>
    </div>

    <admin-footer></admin-footer>
  </div>
</template>

<script>
import adminFooter from '@/components/adminFooter.vue'
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "Login",

  components: {
    adminFooter
  },

  data() {
    return {
      format: {}
    }
  },

  methods: {

    async nav(type) {
      /*this.format.userName = 'caozw@enable-software.com';
      this.format.password = '12345678';*/

      // yuyifei@enable-software.com
      // 12345678

      //tuyao715@qq.com
      //12345678
      let data = this.format;
      if(type == 1) {
        let res = await this.$axios({url: `/compUser/login`, data, method: "post"});
        if(res.code == '000') {
          //成功
          localStorage.setItem(LOCAL_STORAGE_KEY.RPA_TOKEN, res.data.token);
          localStorage.setItem(LOCAL_STORAGE_KEY.USER_INFO, JSON.stringify(res.data));
          this.$router.push('/');
        }
        // console.log(res)
        //this.$router.push('/');
      } else if(type == 2) {
        this.$router.push('/modify');
      }
    }
  }
}
</script>

<style scoped src="../../style/login.css">


</style>
