<template>
  <div class="footer">
    <p>サイト上には「検索条件」及び「更新日時が新しい案件」に基づいて表示されます</p>
    <p>(C)PreciousPartnersCo.,Ltd.</p>
  </div>
</template>

<script>
export default {
  name: "adminFooter"
}
</script>

<style scoped>
  .footer {
    /*width: 100rem;*/
    width: 100%;
    min-width: 1000px;
    box-sizing: border-box;
    overflow: hidden;
    /*height: 2.3rem;*/
    background: #363A3F;
    /*position: absolute;*/
    bottom: 0;
    left: 0;
    z-index: 1;

    text-align: center;
    padding: .5rem;
  }

  .footer p {
    
    font-style: normal;
    font-weight: 400;

    /* or 125% */

    text-align: center;

    color: #FFFFFF;
    font-size: .6rem;

    line-height: 1rem;
  }
</style>
